<script context="module" lang="ts">export { specification } from "./spec";
</script>
  
<script lang="ts">import { onMount } from 'svelte';
import { t } from "js/i18n";
import BaseAtom from 'components/cards/atoms/BaseAtom.svelte';
import 'components/cards/atoms';
import { VideoParticle } from 'components/cards/particles/YinzCam';
import TextParticle from 'components/cards/particles/YinzCam/Core/Text/TextParticle.svelte';
import { getPublishDuration, getVideoPlaybackToken, handleVideoError, handleVideoReady } from "components/cards/utilities";
import ImageParticle from "components/cards/particles/YinzCam/Core/Image/ImageParticle.svelte";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let publishDateTime = undefined;
export let titleText = undefined;
//export let descriptionText: TextParticleProps = undefined;
export let dateTimeText = undefined;
export let video = undefined;
export let showInfoBox = undefined;
export let imgClockIcon = undefined;
let atomWidthRem;
$: publishDateTimeDisplay = getPublishDuration(publishDateTime, $t);
let videoEl;
onMount(() => {
    return () => {
        if (videoEl) {
            videoEl.pause();
            videoEl.currentTime = 0;
        }
    };
});
</script>

<style>
  div.outer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.player {
    max-width: 100%;
  }

  div.info-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.75rem 0;
    gap: 0.5rem;
  }
  .info-box-date{
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }</style>

<BaseAtom _isotope="VideoPlayerAtom" {...container} bind:_clientWidthRem={atomWidthRem}>
  <div class="outer">
    <div class="player">
      <VideoParticle on:ready={handleVideoReady} on:error={(e) => handleVideoError(e, slug)} _getPlaybackToken={getVideoPlaybackToken} {...video}  />
    </div>
    {#if showInfoBox && (titleText?.text || publishDateTime)}
    <div class="info-box">
      {#if titleText?.text}
      <div class="info-box-title">
        <TextParticle {...titleText} />
      </div>
      {/if}
      {#if publishDateTime}
      <div class="info-box-date">
        <div>
          <ImageParticle {...imgClockIcon} _block={true}/>
        </div>
        <div>
          <TextParticle {...dateTimeText} text={publishDateTimeDisplay} _block={true}/>
        </div>
      </div>
      {/if}
    </div>
    {/if}
  </div>
</BaseAtom>

