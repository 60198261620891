import { generateRootObjectSchema, generateHtmlSchema, generateTranslationSchema, generateBooleanSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";
import { TextParticleSpec } from "../Text";

export const RichTextParticleSpec = new BaseParticleSpec("Text", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  html: generateHtmlSchema("Rich Text (HTML)", "The HTML markup for this text."),
  translations: generateTranslationSchema("Rich Text", {
    html: generateHtmlSchema("Rich Text (HTML)", "The translated HTML markup for this text."),
  }),
  textDefaults: TextParticleSpec.generateSchema("Text Defaults", "The default styling for rich text that is not otherwise styled."),
  fillWidth: generateBooleanSchema("Fill Width", "Request that the HTML content fill the entire width of its container. Only applies to block-level layout.", {
    defaultValue: false
  }),
  alignment: generateStringSchema("Content Alignment", "The alignment of the HTML content within its container, if the content does not fill the entire width.", {
      choices: [ 'start', 'center', 'end' ],
      choiceTitles: [ 'Left', 'Center', 'Right' ],
      defaultValue: 'left'
    }),
}));

export const specification = RichTextParticleSpec;

export type RichTextParticleSchema = GetParticleSchemaType<typeof RichTextParticleSpec>;

export type RichTextParticleProps = GetParticlePropsType<typeof RichTextParticleSpec>;
